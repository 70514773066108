<template>
  <a-card class="mb10">
    <a-button size='small' @click="()=>{$router.go(-1)}">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title" v-if='$route.query.type == "look"'>短信模板详情</span>
  </a-card>
  <a-card :bordered="false">
    <SmallSquare content="申请详情" />
    <a-form ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item ref="send_business" label="品牌商编号">
        {{form.brand_no || '-'}}
      </a-form-item>
      <a-form-item ref="send_type" label="公司名称">
        {{form.brand_company || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="品牌商账号">
        {{form.brand_account || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="模板名称">
        {{form.name || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="模板内容">
        {{form.content || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="申请说明">
        {{form.reason || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="申请时间">
        {{form.create_time || '-'}}

      </a-form-item>
      <SmallSquare content="审核详情" />
      <a-form-item ref="name" label="审核状态">
        {{form.ali_status_text || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="反馈时间">
        {{form.update_time || '-'}}

      </a-form-item>
      <a-form-item ref="name" label="审核反馈">
        {{form.remark || '-'}}

      </a-form-item>
      <a-form-item :wrapper-col="{ span: 6, offset: 2 }">
        <a-button class="m-r-20" type="primary" @click="onSubmit" :loading='loading' v-if="form.ali_status==0">
          提交审核
        </a-button>
        <a-button @click="()=>{$router.go(-1)}">
          返回
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
  <a-modal v-model:visible="submitShow" title="提交审核" :width="700" centered @ok="submitAli" @cancel="remark='';submitShow=false">
    <div class="s_flex_bian">
      <p class="width-72">模板内容：</p>
      <p class="width88">{{form.content}}</p>
    </div>
  </a-modal>
</template>
<script>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { smstplDetail,setSmstpl } from "@/api/brand";
export default {
  components: { LeftOutlined },
  setup(props, { emit }) {
    //pass
    const form = ref({});
    const state = reactive({
      loading: false,
      typeList: [],
      formData: {},
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      submitShow: false,
    });
    const $route = inject("$route");
    const $router = inject("$router");
    const _lodash = inject("_lodash");
          const $Modal = inject('$Modal')
    const getMore = async () => {
      const hide = message.loading("正在加载中...", 0);
      var res = await smstplDetail({id:$route.query.id})
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        form.value=res.data
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      setTimeout(hide);
    };
    const ruleForm = ref();
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(async () => {
         state.submitShow = true
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const submitAli = (e) => {
      $Modal.confirm({
        title: "提交至阿里云",
        content: "您确定要将该消息模板提交至阿里云审核吗？",
        centered: true,
        onOk: async () => {
          let res = await setSmstpl({
            id:$route.query.id,
            reason:"",
          }).then((res) => res.data);
          if ($iscode(res)) {
            message.success(res.msg);
            state.submitShow = false;
          } else {
            message.error(res.msg);
          }
        },
      });
    };
    onMounted(() => {
      if ($route.query.type == "look") {
        getMore();
      }
    });
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
      submitAli,
    };
  },
};
</script>
<style>
</style>